/*
* @desc 工具类
* */

import jointImageSuffix from '@kkmh/image-format';

const ua = (() => {
    let userAgent = navigator.userAgent.toLowerCase();
    let is_iphone_qqnative = (/(iphone|ipod|ipad)/i.test(userAgent) && / QQ/i.test(navigator.userAgent));
    let is_android_qqnative = (/android/.test(userAgent) && /MQQBrowser/i.test(navigator.userAgent) && /QQ/i.test(navigator.userAgent));

    return {
        iphone: /(iphone|ipod|ipad)/i.test(userAgent),
        android: /android/.test(userAgent),
        weixin: /micromessenger/.test(userAgent),
        weibo: /weibo/.test(userAgent),
        qqnative: (is_android_qqnative || is_iphone_qqnative),
        qq: /qq/.test(userAgent),
        kkmh: /kuaikan\//.test(userAgent),
        alipay: /alipay/.test(userAgent),
        meipai: /com.meitu.mtmv/.test(userAgent),
        pc: !/(iphone|ipod|ipad|android|webos|blackberry)/.test(userAgent),
    };
})()

const _platformInfo = (() => {
    let ua = navigator.userAgent.toLowerCase();
    let isInApp = ua.indexOf('kkmh') != -1 || ua.indexOf('kuaikan') != -1;
    let isAndroid = ua.indexOf('android') > -1 || navigator.userAgent.indexOf('linux') > -1;
    let isIOS = ua.indexOf('iphone') > -1;

    return {
        isInApp,
        isAndroid,
        isIOS
    }
})()

/*
* @desc 获取ios系统版本号
* */
const iosSysVersionCode = (() => {
    let iosUA= navigator.userAgent.toLowerCase();
    let ver = iosUA.match(/cpu iphone os (.*?) like mac os/);
    if(ver && ver.length > 1){
        let ios_version_list = ver[1].split('_');
        if(ios_version_list.length > 0){
            let ios_version = ios_version_list[0];

            return ios_version;
        }
    }

    return null;
})()

/*
* @desc 获取系统版本号
* */
const getVersion = () => {
  let version = '';
  const ua = navigator.userAgent.toLowerCase();
  let re = ua.match(/\/[0-9]{6}\(/) || [];
  if (re && re[0]) {
      re = re[0].match(/[0-9]{6}/) || [];
      version = re[0] || '';
  }
  return version;
}

/*
* 处理图片后缀(仅用于服务端下发的链接拼接，本地的不使用这种）
* 可查看图片处理sdk {@link https://wiki.quickcan.com/pages/viewpage.action?pageId=36244672}
* @param {Object} imageInfo
* @param {string} [imageInfo.value=''] - 图片地址
* @param {number | string} imageInfo.width - 图片展示宽度（以750px设计稿为基数）
* @param {string} [imageInfo.q=h] - 要展示的图片的质量（h：高质量；l：低质量）
* @param {boolean} [imageInfo.p=false] - 是否支持图片渐进式
* @param {boolean} imageInfo.social - 是否是社区图片
* @return {string}
* */
const _dealImageSuffix = ({value: originSrc = '', width, q = 'h', p = false, social}) => {
    if(!originSrc) {
        return '';
    }

    // 漫剧视频封面为腾讯云图片，不添加七牛参数
    if(/vod2\.myqcloud\.com/.test(originSrc)) {
        return originSrc;
    }

    let isSocial = false;
    if(social === void 0) {
        //let socialDomainReg = /f(1|1s|5|6)\.kkmh\.com/;
        let videoDomainReg = /video-social\.kkmh\.com/;
        let videoDomainRegStag = /video-social-stag\.kkmh\.com/;
        let avatarDomainReg = /(f[234]|(f2)|i1s|f12stag)\.kkmh\.com/;

        if(videoDomainReg.test(originSrc)) {
            return originSrc;
        }
        if(videoDomainRegStag.test(originSrc)) {
            return originSrc;
        }

        /*
        * 判断是否是社区图片(社区图片不截取）
        * */
        isSocial = !avatarDomainReg.test(originSrc) || originSrc.indexOf('tncache1-f1.v3mh.com') > -1;
    } else {
        isSocial = !!social;
    }

    return jointImageSuffix({src: originSrc, width: width, quality: q, p, intercept: !isSocial});
}

const _kkAppVersion = (() => {
    var ua = navigator.userAgent;
    var version = '0';
    var re = ua.match(/\/[0-9]{6}\(/) || [];
    if (re && re[0]) {
        re = re[0].match(/[0-9]{6}/) || [];
        version = re[0] || '';
    }

    return +version;
})()

/*
* 判断是不是json字符串
* @param {string} str -字符串
* */
const _isJSON = (str) => {
    if(typeof str === 'string') {
        try {
            let jsonObj=JSON.parse(str);

            if(Object.prototype.toString.call(jsonObj) === '[object Object]') {
                return true;
            } else{
                return false;
            }
        } catch(e) {
            return false;
        }
    }

    return false;
}

export {ua, _platformInfo, iosSysVersionCode, _dealImageSuffix, _kkAppVersion, _isJSON, getVersion}
