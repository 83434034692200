import Vue from 'vue'
import App from './App.vue'
import {_platformInfo, _dealImageSuffix, _isJSON} from '@/assets/js/util'
import hybrid from "@kk/hybrid/src/index"
import VueClipboard from 'vue-clipboard2'
import lazyLoad from '@kk/vue-lazy';
import './css/ele.css'
import { Row, Col, Input, Button, Select, Option, Slider, Checkbox, Tooltip, Loading, Progress, Popover } from 'element-ui'
import FastClick from 'fastclick'
import { nanoid } from 'nanoid'
import { hash, jCode } from './js/utils'

FastClick.attach(document.body);
Vue.use(lazyLoad);
Vue.use(VueClipboard)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Slider)
Vue.use(Checkbox)
Vue.use(Tooltip)
Vue.use(Loading)
Vue.use(Progress)
Vue.use(Popover)
window.hybrid = hybrid
Vue.prototype._platformInfo = _platformInfo
Vue.prototype._createLoading = () => {
    return Loading.service({
        lock: true,
        text: '正在加载, 请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
}

let aid = localStorage.getItem('aid');
if (!aid) {
    const userAgent = navigator.userAgent.trim();
    aid = jCode(nanoid()) + new Date().getTime() + hash(userAgent)
    localStorage.setItem('aid', aid);
}
Vue.prototype._aid = aid;
async function init() {
    new Vue({
        el: '#app',
        components: {
            App,
        },
        template: '<App></App>',
    })
}

init()
