<template>
    <component id="app" :is="currentComp"></component>
</template>

<script>
import axios from 'axios';

export default {
    components: {
        'native': () => import('./views/h5.vue'),
        'pc': () => import('./views/pc.vue')
    },
    data () {
        return {
            currentComp: 'native'
        }
    },
    created() {
        // this.currentComp = this._platformInfo.isInApp ? 'native' : 'pc'
        axios.request({
            method: 'post',
            url: `${process.env.ai_domain}/activity/aiDraw/reportData?type=0` // 0-页面访问次数，1-图片保存次数
        });
    }
}
</script>

<style>

#app.hidden {
    overflow: hidden;
    height: 100vh;
}
.kk-toast-wrapper {
    z-index: 9999 !important;
}
img {
    width: 100%;
    display: block;
}
.large-text {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: #222;
}
.main-text {
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    color: #222;
}
.sub-text {
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    color: #999;
}
.normal-text {
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    color: #222;
}
.blue-text {
    color: #91ADFF;
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.scale-down img {
    height: 100%;
    object-fit: scale-down;
}

.icon-check {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(./imgs/icon-ready.png) no-repeat 0 0 / 100% 100%;
}
.icon-check.checked {
    background: url(./imgs/icon-checked.png) no-repeat 0 0 / 100% 100%;
}
.slide-fade-enter-active {
	transition: all 0.3s;
}

.slide-fade-leave-active {
	transition: all 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
	{
	transform: translateY(200%);
}

</style>