import { ua } from '@kkmh/utils'
/**
 * 统一文件名
 * @param file 图片文件对象
 * @param key 自定义的文件名
 * @returns xxx.jpg
 */
export function formatImageKey(file: File, key?: string) {
    const suffix = file.type.split('/')[1] || 'png'
    const random = Math.random().toString().split('.')[1]
    return (key || new Date().getTime() + '_' + random) + '.' + suffix
}

function base64ToBlob(code) {
    let parts = code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], {
        type: contentType,
    })
}
function blobToFile(blob: Blob, fileName = new Date().getTime().toString()) {
    return new File([blob], fileName, {type: blob.type})
}
export function dataURLToFile(url: string) {
    return blobToFile(base64ToBlob(url))
}

// 通过请求下载
export function download(imgSrc: string) {
    return new Promise<boolean>((resolve) => {
        let image = new Image()
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = imgSrc
        image.onload = function () {
            let canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            let context = canvas.getContext('2d')
            context.drawImage(image, 0, 0, image.width, image.height)
            let url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
            let a = document.createElement('a')
            let event = new MouseEvent('click')
            a.download = 'image-' + new Date().getTime()
            if (ua.android) {
                let blob = base64ToBlob(url)
                a.href = URL.createObjectURL(blob)
            } else {
                a.href = url
            }
            a.dispatchEvent(event) // 触发a的单击事件
        }
    });
}
// 打开新页面
export function loadUrl(imgSrc: string) {
    let a = document.createElement("a");
    a.setAttribute("href", imgSrc);
    a.setAttribute("target", "_blank")
    document.body.appendChild(a);

    a.click();
    return Promise.resolve(true)
}

export function getRandomItem(arr: Array<any>, len: number) {
    let result = []
    for (let index = 0; index < len; index++) {
        const random = Math.floor(Math.random() * arr.length)
        result = result.concat(arr.splice(random, 1))
    }
    return result
}

export function getSource() {
    if (ua.pc) return 'pc'
    if (ua.kkmh) return '快看'
    if (ua.weibo) return '微博'
    if (ua.weixin) return '微信'
    if (ua.qqnative) return 'QQ'
    if (/[b|B]ili/.test(navigator.userAgent)) return 'b站'
    if (/discover/.test(navigator.userAgent)) return '小红书'
    return '其他'
}

export const hash = (str) => {
  let hashCode = 0;
  for (let i = 0; i < str.length; i++) {
      hashCode = hashCode + str.charCodeAt(i);
  }
  return hashCode.toString();
};

export const jCode = (code) => {
  let result = '';
  for(let i = 1; i< code.length; i++){
      result += code.charCodeAt(i)
  }
  return result;
};